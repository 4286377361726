import {MarketingPixelsPlugin} from "./helpers/marketing-pixels/MarketingPixelsPlugin";
import {AbIAPClientStripe} from "./helpers/iap/AbIAPClientStripe";
import {StripeWebPlugin} from "./helpers/iap/StripeWebPlugin";
import {IAPManager} from "./helpers/iap/IAPManager";
import {A2UNativeBrowser} from "a2u-capacitor-native-browser-plugin";
import {PermissionCall} from "a2u-capacitor-permission-plugin";
import {marked} from "marked";
import {ChatProcessor} from "app-to-up-vue-renderer/src/renderer/components/ChatBot/ChatProcessor.js";
import {FirebaseAnalytics} from "./helpers/Analytics/FirebaseAnalytics";
import {AuthPromoCodePlugin} from "./helpers/Auth/AuthPromoCodePlugin"
import {Topics} from './db/Topics.js';
import {FriendPhoto} from './db/FriendPhoto.js';
import {Chat} from './db/Chat.js';
import {SessionsSchedule} from './db/SessionsSchedule.js';
import {ChatListDb} from './db/ChatListDb.js';
import {DefaultMessages} from './db/DefaultMessages.js';
import {Gifts} from './db/Gifts.js';
import {UserChat} from './db/UserChat.js';
import {BotForRolePlay} from './db/BotForRolePlay.js';
import {Users} from './db/Users.js';
import {Avatars} from './db/Avatars.js';
import {Professions} from './db/Professions.js';
import {Interests} from './db/Interests.js';
import {AvatarFeatures} from './db/AvatarFeatures.js';
import {Tags} from './db/Tags.js';
import {VoiceOnSubscreptionScreenForCalling} from './db/VoiceOnSubscreptionScreenForCalling.js';
import {Reports} from './db/Reports.js';

export const moduleConfig = {
  plugins: {MarketingPixelsPlugin, AbIAPClient: AbIAPClientStripe, StripeWebPlugin, IAPManager, A2UNativeBrowser, PermissionCall, MarkdownParser: marked, AnalyticsProvider: FirebaseAnalytics.getInstance(), AuthPromoCodePlugin},

  mixin: {
    async created() {
      
    },

    async beforeUnmount() {
      
    },

    methods: {

      // Init after a2u initialization
      async initModulesOnAppInit(a2u) {
        // Log the code version
        console.log(`Init modules on app init`, a2u?.getCodeVersion());

        
        // Init chat processor
        await ChatProcessor?.init.apply(a2u, ['ltOYWt_sls']);
        

        // Init chat processor
        await ChatProcessor?.init.apply(a2u, ['7b3GwSmejT']);
        
      },
    }
  },

  customDbModels: {
    'topics': Topics,
'friend_photo': FriendPhoto,
'chat': Chat,
'sessions_schedule': SessionsSchedule,
'chat_list_db': ChatListDb,
'default_messages': DefaultMessages,
'gifts': Gifts,
'user_chat': UserChat,
'bot_for_role_play': BotForRolePlay,
'users': Users,
'avatars': Avatars,
'professions': Professions,
'interests': Interests,
'avatar_features': AvatarFeatures,
'tags': Tags,
'voice_on_subscreption_screen_for_calling': VoiceOnSubscreptionScreenForCalling,
'reports': Reports
  },
}
